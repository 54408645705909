import Router from "next/router";

/**
 * redirectTo
 * @param to
 * @param res
 */
 export const redirectTo = (to, { res } = {}) =>{
  to = encodeURI(to);
  if (res) {
    res.writeHead(307, { 'Location': to })
    res.end()
  } else {
    Router.replace(to).then(()=>{})
  }
}

export default redirectTo;